import { TasksData } from '../../../api/eva/tasks/task.get';
import TableCellRaw from '@mui/material/TableCell';
import TaskIsWorksSwitch from './TaskIsWorksSwitch';
import TaskIsAutoExecutableSwitch from './TaskIsAutoExecutionSwitch';
import React, { useContext, useEffect, useState } from 'react';
import appContext from '../../../utils/context/appContext';
import patch, {
  TaskIsAutoExecutablePatchRequest,
  isResponseOK,
  TaskIsAutoExecutablePatchResponseMessageOk,
} from '../../../api/eva/tasks/task.is-auto-executable.patch';



const TaskSwitches = (props: { task: TasksData }) => {
  const MainContext = useContext(appContext);
  const [isAutoExecutable, setIsAutoExecuted] = useState<boolean>(props.task.isAutoExecutable);
  const [isAutoExecutableDisabled, setIsAutoExecutedDisabled] = useState<boolean>(!props.task.isWorksOnUsersCampaigns || props.task.type===1);
  useEffect(() => {
    setIsAutoExecutedDisabled(!props.task.isWorksOnUsersCampaigns || props.task.type===1); // системные таски выполняются независимо
    setIsAutoExecuted(props.task.isAutoExecutable);
  }, [props.task]);
  const updateIsAutoExecuted = (newIsAutoExecuted: boolean) => {
    const buildRequest = (): TaskIsAutoExecutablePatchRequest => {
      return {
        isAutoExecutable: newIsAutoExecuted
      }
    }

    setIsAutoExecutedDisabled(true);

    // отключение кнопки, если нет подписки на таску
    if (!props.task.isWorksOnUsersCampaigns) {
      props.task.isAutoExecutable = false;
      setIsAutoExecuted(false);
      return;
    }

    patch(props.task.id, buildRequest()).then(r => {
      setIsAutoExecutedDisabled(false);

      if (!isResponseOK(r)) {
        setIsAutoExecuted(isAutoExecutable);
        MainContext.notify(r.message as string, 'error');
        return;
      }

      let message = r.message as TaskIsAutoExecutablePatchResponseMessageOk;
      props.task.isAutoExecutable = message.data.isAutoExecutable;
      setIsAutoExecuted(message.data.isAutoExecutable);
    });
  };
  const onChangeExecution = (checked: boolean, event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    if (checked) {
      updateIsAutoExecuted(true);
    } else {
      updateIsAutoExecuted(false);
    }
  };
  const disableExecutionSwitch = () => {
    setIsAutoExecuted(false);
    props.task.isAutoExecutable = false;
    setIsAutoExecutedDisabled(true);
  }
  const enableExecutionSwitch = () => {
    setIsAutoExecuted(false);
    props.task.isAutoExecutable = false;
    setIsAutoExecutedDisabled(false);
  }

  return (<>
    <TableCellRaw>
      <TaskIsWorksSwitch task={props.task} disableExecutionSwitch={disableExecutionSwitch} enableExecutionSwitch={enableExecutionSwitch}/>
    </TableCellRaw>
    <TableCellRaw>
      <TaskIsAutoExecutableSwitch
        disabled={isAutoExecutableDisabled}
        checked={isAutoExecutable}
        onChange={onChangeExecution}
      />
    </TableCellRaw>
  </>);
}

export default TaskSwitches;