//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../../apiKey';

const PATH_URL = '/eva/tasks';

export type TasksRequest = {
};

export function createTasksRequest(): TasksRequest {
  return {  };
}

export function recognizeTasksRequest(): TasksRequest {
  const r = {  };
  const uri = window.location.search.split('?');

  if (uri[1] === undefined)
    return createTasksRequest();

  const params = qs.parse(uri[1], { allowSparse: true, arrayLimit: 1000 });
  for (const key in params) {
    // @ts-ignore
    r[key] = params[key];
  }
  return r;
}

export type TasksResponseOk = {
  'status': number,
  'message': TasksResponseMessageOk,
}

export type TaskParametersData = {
  id: number,
  description: string,
  period: number,
  isSubscribedOnParam: boolean,
  parameterSubscriptionId: null|number,
}

export type TasksData = {
  id: number,
  name: string,
  description: string,
  type: number,
  executionPlan: number,
  lastRun: string,
  isWorksOnUsersCampaigns: boolean,
  isAutoExecutable: boolean,
  parameters: TaskParametersData[]
}

export type TasksResponseMessageOk = {
  data: {
    data: TasksData[]
  },
}

export type TasksResponseError = {
  'status': number,
  'message': TasksResponseMessageError,
}

export type TasksResponseMessageError = string

// @ts-ignore
const key = new apiKey();

export default function get(request: TasksRequest): Promise<TasksResponseOk | TasksResponseError> {
  return axios.get(process.env.REACT_APP_API_URL + PATH_URL, {
    params: request,
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <TasksResponseOk | TasksResponseError>r.data;
  }).catch(e => {
    const resp: TasksResponseError = {
      status: 0,
      message: e.response?.data?.message ?? e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: TasksResponseOk | TasksResponseError): boolean {
  return typeof response.message === 'object';
}