import { createTheme, Paper, TableFooter, ThemeProvider, Tooltip } from '@mui/material';
import React, { ComponentProps, useEffect, useState } from 'react';
import { TasksData } from '../../../api/eva/tasks/task.get';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCellRaw from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { BiCog, BiQuestionMark } from 'react-icons/bi';
import TaskParametersModal from './TaskParametersModal';
import TaskSwitches from './TaskSwitches';
import { DateTime } from 'luxon';


const theme = createTheme({
  components: {},
});



export default function(props: ComponentProps<any> & { tasks: TasksData[]}) {
  const [tasks, setTasks] = useState<Array<TasksData>>([]);
  const [editionTask, setEditTask] = useState<TasksData>(props.tasks[0]);
  const [isOpenTaskParameterEditModal, setIsOpenTaskParameterEditModal] = useState<boolean>(false);


  useEffect(() => {
    setTasks(props.tasks);
  }, [props.tasks]);

  function editTask(tasks: TasksData) {
    setEditTask(tasks);
    setIsOpenTaskParameterEditModal(true);
  }

  const TimeConverter = (seconds: number) => {
    // Константы для преобразования
    const secondsInMinute = 60;
    const secondsInHour = 3600; // 60 * 60
    const secondsInDay = 86400; // 60 * 60 * 24
    const secondsInWeek = 604800; // 60 * 60 * 24 * 7
    const secondsInMonth = 2592000; // 60 * 60 * 24 * 30

    let timeString = '';

    if (seconds >= secondsInMonth) {
      const months = Math.floor(seconds / secondsInMonth);
      timeString += `${months} месяц${months > 1 ? 'а' : ''} `;
      seconds %= secondsInMonth;
    }

    if (seconds >= secondsInWeek) {
      const weeks = Math.floor(seconds / secondsInWeek);
      timeString += `${weeks} недел${weeks > 1 ? 'и' : 'я'} `;
      seconds %= secondsInWeek;
    }

    if (seconds >= secondsInDay) {
      const days = Math.floor(seconds / secondsInDay);
      timeString += `${days} ден${days > 1 ? 'я' : 'ь'} `;
      seconds %= secondsInDay;
    }

    if (seconds >= secondsInHour) {
      const hours = Math.floor(seconds / secondsInHour);
      timeString += `${hours} час${hours > 1 ? 'а' : ''} `;
      seconds %= secondsInHour;
    }

    if (seconds > 0) {
      const minutes = Math.floor(seconds / secondsInMinute);
      timeString += `${minutes} минут${minutes > 1 ? 'ы' : 'а'}`;
    }

    return <div>{timeString.trim() || '0 секунд'}</div>;
  };

  return (<TableContainer component={Paper}>
    <TaskParametersModal task={editionTask} isOpen={isOpenTaskParameterEditModal}
                    toggle={() => setIsOpenTaskParameterEditModal((prev) => !prev)} />
    <ThemeProvider theme={theme}>
      <Table id={'tasks-table'} sx={{ minWidth: 650 }} aria-label='tasks'>
        <TableHead>
          <TableRow>
            <TableCellRaw>Наименование</TableCellRaw>
            <TableCellRaw>Периодичность</TableCellRaw>
            <TableCellRaw>Последний запуск</TableCellRaw>
            <TableCellRaw>Работает по моим кампаниям?</TableCellRaw>
            <TableCellRaw>Авто-выполнение?</TableCellRaw>
            <TableCellRaw>Настройка параметров</TableCellRaw>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map((task) => (
            <TaskRows task={task}></TaskRows>
          ))}
        </TableBody>
        <TableFooter>
        </TableFooter>
      </Table>
    </ThemeProvider>
  </TableContainer>);

  function TaskRows(props: { task: TasksData }) {
    const task = props.task;

    return (
      <React.Fragment>
        <TableRow key={task.id}>
          <TableCellRaw>
            {task.name}
            <Tooltip title={<span dangerouslySetInnerHTML={{ __html: task.description }} />} arrow>
              <span>
                <BiQuestionMark size={20}/>
              </span>
            </Tooltip>
          </TableCellRaw>
          <TableCellRaw>{TimeConverter(task.executionPlan)}</TableCellRaw>
          <TableCellRaw>{DateTime.fromISO(task.lastRun, { setZone: true }).toFormat('dd.MM.yyyy HH:mm')}</TableCellRaw>
          <TaskSwitches task={task}></TaskSwitches>
          <TableCellRaw>
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                editTask(task);
              }}
              href={''}
              title={'Редактировать параметры'} target={'_blank'}>
              <BiCog size={30} />
            </a>
          </TableCellRaw>
        </TableRow>
      </React.Fragment>
    );
  }
}