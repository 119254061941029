//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../../apiKey';

const PATH_URL = '/eva/alerts';

export type AlertsRequest = {
};

export function createAlertsRequest(): AlertsRequest {
  return {  };
}

export function recognizeAlertsRequest(): AlertsRequest {
  const r = {  };
  const uri = window.location.search.split('?');
  if (uri[1] === undefined) return createAlertsRequest();
  const params = qs.parse(uri[1], { allowSparse: true, arrayLimit: 1000 });
  for (const key in params) {
    // @ts-ignore
    r[key] = params[key];
  }
  return r;
}

export type AlertsResponseOk = {
  'status': number,
  'message': AlertsResponseMessageOk,
}

export type AlertTextTypeJson = {
  type: string
  list: CountryTopProfitBundles[]
}

export type CountryTopProfitBundles = {
  country: string,
  bundles: TopProfitBundle[],
}

export type TopProfitBundle = {
  numberInCountry: number,
  appBundle: string,
  country: string,
  osName: string,
  currencyId: number,
  spent: number,
  profit: number,
  countryProfit: number,
}

export type AlertsData = {
  id: number,
  userLogin: string,
  name: string,
  parameters: string,
  text: string,
  reactionResult: string|false
  createdAt: string,
}

export type AlertsResponseMessageOk = {
  data: {
    data: AlertsData[]
  },
}

export type AlertsResponseError = {
  'status': number,
  'message': AlertsResponseMessageError,
}

export type AlertsResponseMessageError = string

// @ts-ignore
const key = new apiKey();

export default function get(request: AlertsRequest): Promise<AlertsResponseOk | AlertsResponseError> {
  return axios.get(process.env.REACT_APP_API_URL + PATH_URL, {
    params: request,
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <AlertsResponseOk | AlertsResponseError>r.data;
  }).catch(e => {
    const resp: AlertsResponseError = {
      status: 0,
      message: e.response?.data?.message ?? e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: AlertsResponseOk | AlertsResponseError): boolean {
  return typeof response.message === 'object';
}